/*
<summary>
   This component is Managing Products & performing CRUD operations on the products.
   CRUD (Create, Read, Update, Delete)
   Developer: Raman Sharma, Created Date:13-Aug-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/store-provider";
import IPageInfo, { IActionFormatter, IPageLimitInfo } from "../../models/ICommon";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import Pagination from "../../shared-components/Grid/Pagination";
import { formatMessage } from "../../translations/format-message";
import "./product.css";
// import AddProduct from "./components/kms-product/add-edit-kms-product";
import toast from "react-hot-toast";
import noMessageIcon from "../../assets/Images/find.png";
import { ModuleType } from "../../constants/enums/module-type-enum";
import { showIconsRoleBase } from "../../helpers/action-permission-helper";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";

import SlidingPanel from "react-sliding-side-panel";
import productSettingEnum from "../../constants/enums/product-setting-enum";
import { getTenantIdFromLocalStorage } from "../../helpers/localstorage-helper";
import { CustomActionFormatter } from "../../shared-components/Grid/GridFormatter";
import ImagePopupHOC from "../../shared-components/popup/image-popup/image-popup-hoc";
import AddEditWeightedProduct from "./components/add-edit-weighted-product";
import AddWeightedProductPopupHOC from "./hoc/add-weighted-product-popup";
import WeightedProductDetails from "./weighted-product-details";
import { Form as BsForm, Button, Modal } from "react-bootstrap";

const Product = (props: any) => {
  const { productStore, preferencesStore, authStore, tenantStore } = useStore();
  const { getUserType } = authStore;
  const {
    inProgress,
    error,
    GetProductListService,
    allWeightedProduct,
    productList,
    deleteProductState,
    DeleteProductService,
    resetAddUpdateProductState,
    addUpdateProductState,
    resetDeleteProduct,
    selectedTenantId,
    setSelectedTenantId,
    resetProductDetail,
    weightedProductList,
    GetWeightedProductListService,
    weightedProductState,
    updateWeightedProductSkuState,
    resetUpdateWeightedProductState,
    getWeightedProductState,
    resetWeightedProductDetail
  } = productStore;

  const {
    productSetting,
    setProductSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
    tenantAccess
  } = preferencesStore;

  const {
    GetAllTenants,
    allTenantOptions,
  } = tenantStore;

  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allProductsList: Array<any> = allWeightedProduct;
  const [selectedRow, setSelectedRow] = useState<any>({});
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [openPanel, setOpenPanel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleAutoRefresh, setToggleAutoRefresh] = useState(false);

  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: productSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
    closePanelhandler();
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setProductSetting(productSettingEnum.ProductLimitConfig, parseInt(evt.target.value));
  };

  const getProductListServiceHandler = () => {
    if (Number(getTenantIdFromLocalStorage()) >= 0) {
      GetWeightedProductListService(pageInfo.currentPage, pageLimitInfo.pageLimit, Number(getTenantIdFromLocalStorage()));
    }
    else {
      GetWeightedProductListService(pageInfo.currentPage, pageLimitInfo.pageLimit, selectedTenantId);
    }
  };

  const getTenantsListServiceHandler = () => {
    GetAllTenants();
  };

  function handleTenantChange(value: any) {
    closePanelhandler();
    setSelectedTenantId(value);
    setIsLoading(true);
  }

  //Refresh automatically after 10 seconds
  useEffect(() => {
    if (toggleAutoRefresh) {
      getTenantsListServiceHandler();
      const interval = setInterval(() => {
        getProductListServiceHandler();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [toggleAutoRefresh]);

  useEffect(() => {
    // getProductListServiceHandler();
    getTenantsListServiceHandler();
    setIsLoading(false);

  }, [isLoading])

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      getProductListServiceHandler();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: weightedProductList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWeightedProduct]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      getProductListServiceHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateGroupstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateProductState.success) {
      resetAddUpdateProductState();
      getProductListServiceHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateProductState.success]);

  useEffect(() => {
    if (updateWeightedProductSkuState.success) {
      resetUpdateWeightedProductState();
      getProductListServiceHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateWeightedProductSkuState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteProductSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteProductState.success) {
      toast.success(formatMessage("deleted_success"));
      props.deletePopupToggleHandler();
      resetDeleteProduct();
      getProductListServiceHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductState.success]);

  // useEffect(() => {
  //   if (resendActivationLinkState.success) {
  //     toast.success(formatMessage("activation_link_send"));
  //     resetAccountActivationLink();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [resendActivationLinkState.success]);

  /**
   * This function shows the add product pop up from where we can add new product and update existing product. we use id to identify
   * that we are adding or updating product.
   * "Id == -1" : we are adding new product
   * "Id != -1" : means we are updating existing product
   */
  const openAddProductHandler = (row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    closePanelhandler();
    resetProductDetail();
    setSelectedId(row.Id);
    props.addToggleHandler();
  };

  /**
* The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
* dependency "selectedTenantId" of the useEffect changed.
*/
  useEffect(() => {
    getProductListServiceHandler();
    // if (selectedTenantId >= 0) {
    // }
  }, [selectedTenantId])

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      getProductListServiceHandler();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  // const resetActivationLinkHandler = (row: any) => {
  //   ResendAccountActivateLinkService({
  //     ProductId: row.Id,
  //     Email: row.Email,
  //     AccountStatus: Object.keys(accountStatusEnum).indexOf(row.AccountStatus)
  //   })
  // }

  /**
   * This function deletes the product by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteProductService(selectedId, selectedTenantId);
    // DeleteProductService(selectedId, getTenantAccessFromLocalStorage());
  };

  /**
   * This function shows the delete product confirmation popup component and sets the seleted product Id
   */
  const deleteClickHandler = (row: any, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSelectedId(row.Id);
    event.stopPropagation();
    closePanelhandler();
    resetProductDetail();
    props.deletePopupToggleHandler();
  };

  /**
   * This function shows the change password popup component and sets the seleted product Id
   */
  // const changePasswordClickHandler = (row: { Id: number }) => {
  //   setSelectedId(row.Id);
  //   props.changePasswordToggleHandler();
  // };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      getProductListServiceHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    getProductListServiceHandler();
    closePanelhandler();
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedRow(row);
    setOpenPanel(true);
    resetWeightedProductDetail();
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
    resetWeightedProductDetail();
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase([
    {
      dataField: "isEdit",
      handler: openAddProductHandler,
      icon: (
        <svg id="Layer_2" fill="#2b4c21" width="21"
          height="21" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.43 24.75">

          <g id="_レイヤー_3" data-name="レイヤー 3">
            <g>
              <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
              <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
              <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
              <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
              <rect x="13.01" y="8.8" width="11.25" height="1.3" transform="translate(-1.22 15.95) rotate(-45)" />
            </g>
          </g>
        </svg>
      ),
      isDisable: false,
      title: "edit",
      action: "edit",
      varient: "primary",
    },
    // {
    //   dataField: "isDelete",
    //   handler: deleteClickHandler,
    //   icon: (
    //     <svg id="Layer_2" fill="#2b4c21" width="18"
    //       height="18" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 20.66">

    //       <g id="_レイヤー_3" data-name="レイヤー 3">
    //         <g>
    //           <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
    //           <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
    //           <rect x="11.68" y="6.8" width="1.16" height="9.8" />
    //         </g>
    //       </g>
    //     </svg>
    //   ),
    //   isDisable: false,
    //   title: "delete",
    //   action: "delete",
    //   varient: "danger",
    // },

  ], ModuleType.ProductManagement)

  /**
   * The column constant defines the column description for the product grid (table)
   */
  const columns: any = [
    {
      dataField: "Name",
      text: formatMessage("display_name"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Sku",
      text: formatMessage("SKU"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "WeightUnit",
      text: formatMessage("WeightUnit"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Weight",
      text: formatMessage("weight"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      sort: true
    },
    {
      dataField: "AddedDate",
      text: formatMessage("added_date"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      sort: true
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: actionIcons.length > 0 ? false : true,
    },
  ];

  return (
    <React.Fragment>
      {props.showAddWeightedProductPopup && (
        <AddEditWeightedProduct id={selectedId} modalClosed={props.addToggleHandler} />
      )}

      {
        props.showDeletePopup
        && (
          <DeletePopup
            title="delete_product"
            modalSubmit={deleteHandler}
            modalClosed={props.deletePopupToggleHandler}
            message="message_delete_product_confirm"
            isLoading={deleteProductState.inProgress}
          />
        )
      }


      <div className="icon-nav navbar-fixed-top device-nav-bar productTopNav">
        <div className="row align-items-center">
          <div className="col-md-6 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="weigheted_products" />
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="weigheted_products" />
                </li>
              </ol>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <ul className="add-list">

              {/* {
                isOperationPermittedRoleBase(ModuleType.ProductManagement, 'Add')
                &&
                (
                  <li
                    title={formatMessage("add_product")}
                    className={` ${getUserType === "SuperAdministrator" ? "d-none" : ""} `}
                  >
                    <a
                      className="btn btn-primary"
                      data-toggle="modal"
                      onClick={(event: any) => openAddProductHandler({ Id: -1 }, event)}
                      data-testid="modalbtn"
                      data-target="#addProduct"
                    >
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-plus m-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                      <FormattedMessage id="add_product" />
                    </a>
                  </li>
                )
              } */}

              <li>
          
                {
                  getUserType === "SuperAdministrator" &&
                  <select
                    className="custom-select form-control me-2"
                    value={selectedTenantId}
                    onChange={(
                      e: React.ChangeEvent<HTMLSelectElement>
                    ) => {
                      let value = parseInt(e.target.value);
                      handleTenantChange(value)
                      // if (value < 0) toast.error(formatMessage("please_select_any_tenant"));
                      // else handleTenantChange(value);
                    }}
                  >
                    {allTenantOptions.map((data: any, i: number) => (
                      <option key={i} value={data.id}>
                        {i == 0 ? formatMessage(data.value) : data.value}
                      </option>
                    ))}
                  </select>
                }
              </li>
       
              <li title={formatMessage("refresh")} className="d-flex">
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
            
                </a>
              </li>
        

              <li title="Toggle Auto Refresh?" className="d-flex">
              
                <BsForm.Check
                  type="switch"
                  id="custom-switch"
                  className="btn"
                  // label="Toggle Auto Refresh?"
                  onClick={() => setToggleAutoRefresh(!toggleAutoRefresh)}
                />
                  <p className="auto-refresh">Auto Refresh</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* {<FormLoader loading={inProgress || deleteProductState.inProgress || addUpdateProductState.inProgress} />} */}
      <div className={`${openPanel ? "panelOpen" : ""}`}>
        <div className="card ">
          {
            (
              weightedProductState.inProgress || getWeightedProductState.inProgress)
            && <FormLoader loading={weightedProductState.inProgress || getWeightedProductState.inProgress} />
          }
          {allProductsList.length > 0 ? (
            <>
              <div className="tableDesign row-eq-height tableDevices changingPanel p-3">
                <div className="table-responsive">
                  {allProductsList && (

                    <Grid
                      data={allProductsList}
                      columns={columns}
                      keyField="Id"
                      loading={false}
                      rowClasses={rowClassFormat}
                      rowEvents={{ onClick: rowEvents }}
                      sort={{ dataField: 'AddedDate', order: 'desc' }}
                    />
                  )}
                </div>
              </div>
              {
                allProductsList.length > 0 ? (
                  <div className="row no-gutters p-3 tableFooter">
                    <div className="col-sm-6 pt-1">
                      <FormattedMessage id="show" />
                      <select
                        data-testid="pageSelect"
                        className="pageLimit"
                        onChange={onPageLimitChanged}
                        value={pageLimitInfo.pageLimit}
                      >
                        {pageLimitOptions.map((op: any) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-sm-end">
                      <Pagination
                        isRefreshPage={false}
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimitInfo.pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                        setIsRefreshPage={setIsRefreshPage}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )
              }
            </>
          ) : (
            <div className="message">
              <div className="row text-center">
                <div className="col-lg-4">
                  <img src={noMessageIcon} alt="" className="no_message" />
                </div>
                <div className="col-lg-8 mt-5 p-3">
                  <h3 className="fw-bold display-6">         <FormattedMessage id="no_data" /></h3>
                  <h4 className="mb-3">{formatMessage("no_product_data_message")}</h4>
                </div>
              </div>
              {
                // isOperationPermittedRoleBase(ModuleType.ProductManagement, 'Add') &&
                // <LoaderButton
                //   text="add_product"
                //   onClick={() => openAddProductHandler({ Id: -1 })}
                // />
              }
            </div>
          )}
        </div>
      </div>
      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 g-0 align-items-center ">
            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
              <h2 className="fw-bold mb-0 mt-1 ms-2 py-2">
                {selectedRow.Name}
              </h2>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 close-icon-detail">
              <span title={formatMessage("close")}>
                <svg
                  onClick={closePanelhandler}
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x ms-auto d-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </div>
          <div className="group_detailsWrapper pb-3">
            <WeightedProductDetails selectedDataId={selectedRow.Id} />
          </div>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default AddWeightedProductPopupHOC(ImagePopupHOC((DeletePopupHOC(observer(Product)))));
